@import url('https://fonts.googleapis.com/css?family=Crete+Round|Muli');

$primary: #861b31;/* MAIN COLOR */
$secondary: #007999; /* SECONDARY COLOR */
$blk: #333; /* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */
$wht: #fafafa;
$border-radius: 0px; /* USE THIS TO SET THE BORDER RADIUS FOR BUTTONS */
$footerLinks: #fafafa;

html, body {
    height: 100%;
}

body {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
}

.flash {
    display: none;
}

q,
blockquote {
    quotes: "“" "”";
} 

.navbarFixed {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 999;
}

/* Change autocomplete styles in WebKit */

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill, 
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    border: 1px solid #0065dd;
    -webkit-text-fill-color: #0065dd;
    -webkit-box-shadow: 0 0 0px 1000px #000 inset;
    transition: background-color 5000s ease-in-out 0s;
}

nav.navbar {
    z-index: 1000;
    border: none;
    border-radius: $border-radius;

    .navbar-nav {
        >li>a {
            font-family: 'Crete Round', serif;
            font-size: 20px;
            text-align: center;
            margin-top: 35px;
            display: flex;
            align-items: center;
            color: $blk;
            @media (max-width: 767px) {
                margin-top: 0;
                padding: 6px 0px;
                display: inline-block;
            }
            &:focus,
            &:active {
                background: transparent;
                color: $blk;
                outline: 0;
            }
            &:hover {
                background: $primary;
                color: $wht;
                @media (max-width: 1024px) {
                    background: transparent;
                    color: $blk;
                    outline: 0;
                }
            }
        }
    }
}

.navbar-right {
    margin-top: 0px;
}

.navbar-toggle {
    margin: 20px 15px 8px 0px;
    border: 1px solid transparent;
    border-radius: 4px;
}


/** LOGIN FORM **/

@mixin btn {
    border: none;
    border-radius: $border-radius;
    background: $primary;
    color: $wht;
    padding: 0.5em 2em;
    font-size: 1.25em;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 1px;
    display: inline-block;
    margin: 2em 0em;
    transition: 0.7s ease all;
    &:hover {
        background: lighten($primary, 10%);
        color: lighten($wht, 10%);
        text-decoration: none;
        @media (max-width: 1024px) {
            background: $secondary;
            color: $wht;
            box-shadow: 0px 0px 5px $blk;
        }
    }
}

.btn-default {
    @include btn;
}

.modal-dialog {
    max-width: 300px;
    text-align: center;
    margin: 6em auto;
    .close {
        display: none;
    }
    .modal-content {
        color: $primary;
        h2 {
            text-align: center;
        }
    }
    input {
        border: 1px solid #777;
        text-align: center;
    }
    input#username,
    input#password {
        @media (max-width: 1024px) {
            font-size: 16px;
        }
    }
    button {
        background: transparent;
        color: $wht;
        display: block;
        border: none;
        padding: 1em 2em;
        margin: 0 auto;
        font-size: 18px;
        &:hover {
            background: transparent;
            color: #fff;
            box-shadow: none;
            text-shadow: 0px 0px 3px $blk;
            border: none;
        }
    }
    input[type="submit"] {
        @include btn;
        display: block;
        width: 75%;
        font-size: 18px;
        margin: 0 auto;
    }
}

.modal-header,
.modal-footer {
    background: $primary;
    color: $wht;
}

.modal-open {
    overflow: auto;
    padding-right: 0px !important;
}

input#username {
    margin-bottom: 20px;
}


/** END LOGIN FORM **/

footer {
    padding: 50px 0px 20px;
    background: $primary;
    color: $footerLinks;
    font-size: 15px;
    a {
        color: $footerLinks;
        white-space: nowrap;
        font-size: 15px;
        @media (max-width: 990px) {
            font-size: 13px;
        }
        &:hover {
            color: lighten($footerLinks, 10%);
            outline: 0 !important;
            text-decoration: none;
        }
        &:focus {
            color: $wht;
            outline: 0 !important;
            text-decoration: none;
        }
    }
    p {
        font-size: 15px;
        @media (max-width: 990px) {
            font-size: 13px;
        }
    }
}

input#username,
input#password {
    width: 100%;
    @media (max-width: 1024px) {
        font-size: 16px;
    }
}

select {
    -moz-appearance: none;
    text-indent: 0.01px;
    text-overflow: '';
}

a,
a:hover,
a:focus,
a:active {
    outline: 0 !important;
}

.logo {
    max-width: 250px;
    padding: 0.5em;
}

@media(max-width: 767px) {
    .logo {
        max-width: 175px;
    }
    .navbar-header a {
        float: left;
    }
}

@media (max-width: 600px) {
    .logo {
        max-width: 125px;
    }
    .navbar-toggle {
        margin-top: 15px;
    }
}

.top-pad {
    padding: 3em;
    background: white;

    @media (max-width: 990px) {
        padding: 1em;
    }    
}

.flex {
    display: flex;
    justify-content: center;
    align-items: center;
    @media  (max-width: 1024px) {
        display: block;
        /* may need to be inline-block */ 
    }
}

.terms,
.privacy {
    p {
        font-size: 16px;
    }
    
    & ol {
        li {
            font-size: 16px;
        }
        & ol li {
            font-size: 15px;
        }
    }
}

.header {
    background: url('../img/banner.jpg');
    background-size: cover;
    background-attachment: fixed;
    padding: 400px 0;
    background-position: top center;

    @media (max-width:1024px) {
        background-attachment: scroll;
        padding: 200px 0;
    }

    @media (max-width:767px) {
        background-attachment: scroll;
        padding: 100px 0;
    }
    @media (max-width:500px) {
        background-attachment: scroll;
        padding: 100px 0;
    }
}

.services {
    position: relative;
}

div#services {
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
  width: 100%;
}


#services a {
  display: inline-block;
  overflow: hidden;
  position: relative;
  text-decoration: none;
}

#services a:before,
#services a:after {
  top: 0;
  background-color: rgb(0, 0, 0);
  content: "";
  display: block;
  height: 100%;
  opacity: 0.5;
  position: absolute;
  width: 50%;
  z-index: 3;
}

#services a:before {
  left: 0;
}

#services a:after {
  right: 0;
}

#services a img {
  border: none;
  display: block;
  z-index: 1;
}

#services a:before,
#services a:after,
#services a span {
  -webkit-transition: all 700ms ease;
  -moz-transition: all 700ms ease;
  -o-transition: all 700ms ease;
  transition: all 700ms ease;
}

#services a span {
  color: black;
  background-color: rgba(255, 255, 255, 0.8);
  bottom: 5%;
  display: block;
  opacity: 0;
  padding: 2% 3%;
  position: absolute;
  text-align: center;
  text-transform: uppercase;
  width: 94%;
  z-index: 2;
}

#services a:hover:before {
  left: -50%;
}

#services a:hover:after {
  right: -50%;
}

#services a:hover span {
  opacity: 1;
}

.headline {
    font-family: 'Crete Round', serif;
    text-transform: uppercase;
    font-size: 70px;
    color: $wht;
    text-align: center;
    text-shadow: 2px 2px 1px $primary;
    /*animation-delay: 18s;*/

    @media (max-width: 767px) {
        font-size: 45px;
    }
}

.tagline {
    font-family: 'Muli', sans-serif;
    font-size: 20px;
    color: $wht;
    text-align: center;
    text-shadow: 2px 2px 1px $primary;
    background: $primary;
}

.subtitle {
    font-family: 'Muli', sans-serif;
    font-size: 20px;
    color: $wht;
    text-align: center;
    text-shadow: 2px 2px 1px $primary;
}

.second {
    background: url('../img/img1.jpg');
    background-size: cover;
    padding: 300px 0;
    background-attachment: fixed;
    background-position: center center;

    @media (max-width: 1024px) {
        background-attachment: scroll;
        padding: 200px 0;
    }
    @media (max-width: 767px) {
        background-attachment: scroll;
        padding: 100px 0;
    }
    @media (max-width: 500px) {
        background-attachment: scroll;
        padding: 50px 0;
    }
}

.title {
    font-family: 'Crete Round', serif;
    font-size: 60px;
    text-shadow: 2px 2px 1px $primary;

    @media (max-width: 500px) {
        font-size: 30px;
    }
}

.text {
    font-family: 'Muli', sans-serif;
    font-size: 20px;
    padding-left: 70px;
    padding-right: 70px;

    @media (max-width: 990px) {
        padding-left: 0;
        padding-right: 0;
    }
}

.title-container {
    z-index:1;
    position:absolute;
    top:35%;
    width:100%;
    text-align:center;
    color:#fff;

    @media (max-width: 990px) {
        top: 25%;
    }

    @media (max-width: 990px) and (orientation: landscape) {
        top: 30%;
    }
}

/* FOR IE8 */
video {
    width: 100% !important;
    height: 100% !important;
    object-fit: cover;

    @media (max-width: 767px) {
        width: auto;
    }
}
/* END IE8*/

.videoBox {
    position: relative;
    width: 100%;
    height: 1000px;
    border-bottom: 10px solid $primary;
    overflow: hidden;

    @media (max-width: 767px) {
        height: 800px;
    }
}

.video-overlay {
 position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0,0,0,0.4);
    transition: all 1s ease;
    

    h1 {
     position: absolute;
     left: 50%;
     top: 50%;
     transform: translateX(-50%) translateY(-50%);
     text-transform: uppercase;
     text-align: center;
    }
}

.center {
    display: block;
    margin: 0 auto;
}

.row {
    margin-left: 0;
    margin-right: 0;
}